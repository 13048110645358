import React from 'react';

class Carregando extends React.Component {
  render() {
    return (
      <p className='loader'> Carregando...</p>
    );
  }
}

export default Carregando;
